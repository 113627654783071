var React = require("react");
const createReactClass = require("create-react-class");
import ReactJson from "react-json-view";
var QRCode = require("qrcode.react");
const Link = require("react-router-dom").Link;
import LoginMethod from "../comps/LoginMethod";
const util = require("../util.js");
const constants = require("../constants.js");
const TeamMode = require("../comps/TeamMode.js");
const TzInfo = require("../comps/TzInfo.js");
const AdsList = require("../comps/AdsList.js");
const Country = require("../comps/Country.js");
const api = require("../api.js");
const download = require("../download.js");
import firebase from "firebase/app";

const ROLE_TYPES = ["관리자", "멤버", "뷰어"];

const TX_TYPE_NAME = ["입고", "출고", "조정", "이동"];

const LTX_TYPE_NAME = {
  1000: "입고",
  1001: "출고",
  1002: "조정",
  1003: "이동",
};

function formatTime(t) {
  if (t) {
    return new Date(t).toLocaleString();
  } else {
    return "";
  }
}

function formatClient(clientName) {
  if (!clientName) {
    return "Unknown";
  } else if (clientName.startsWith("Mozilla")) {
    return "Web";
  } else {
    return clientName;
  }
}

function SessionList({ items }) {
  const [show, setShow] = React.useState(false);
  const linkStyle = {
    textDecoration: "underline",
  };
  if (show) {
    return (
      <div>
        <a onClick={() => setShow(false)} style={linkStyle}>
          닫기
        </a>
        <ul style={{ paddingInlineStart: 10 }}>
          {items &&
            items.map(function (item, i) {
              return (
                <li key={i}>
                  {new Date(item.last_access_time).toLocaleString()} :{" "}
                  {formatClient(item.client)}
                </li>
              );
            })}
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <a onClick={() => setShow(true)} style={linkStyle}>
          세션
        </a>
      </div>
    );
  }
}

function toMapById(data) {
  return data.reduce(function (aux, datum) {
    aux.set(datum.id, datum);
    return aux;
  }, new Map());
}

function renderAttr(attr) {
  return (
    <ul style={{ paddingInlineStart: 10 }}>
      {attr &&
        Object.entries(attr).map(function ([key, value]) {
          return <li key={key}>{String(value)}</li>;
        })}
    </ul>
  );
}

function MemberName({ id, memberById }) {
  const member = memberById.get(id);
  return member ? member.name : `나간 유저 (${id})`;
}

function ProductBasicUnit({ item, isUnitMode, memberById }) {
  return (
    <tr>
      <td>{item.id}</td>
      <td>
        <a href={item.photo_url} target="_blank">
          <img src={item.photo_url} style={{ width: "50px", height: "50px" }} />
        </a>
      </td>
      <td>
        <a href={"https://barcode.bgp.works/" + item.barcode} target="_blank">
          {item.barcode}
        </a>
        <br />
        {item.name}
      </td>
      <td>{renderAttr(item.barcode_attr)}</td>
      {isUnitMode && <td>{renderAttr(item.attr)}</td>}
      <td>
        <MemberName id={item.created_user_id} memberById={memberById} />
      </td>
      <td>{new Date(item.created_time).toLocaleString()}</td>
      <td>{item.quantity}</td>
    </tr>
  );
}

function ProductsListBasicUnit({ items, itemsCount, isUnitMode, memberById }) {
  return (
    <div>
      <p>상품 개수: {itemsCount}</p>
      <p>
        마지막 입력일:{" "}
        {items && items.length > 0 && formatTime(items[0].created_time)}
      </p>
      <table className="pure-table pure-table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>사진</th>
            <th>상품</th>
            <th>제품 속성</th>
            {isUnitMode && <th>유닛 속성</th>}
            <th>유저</th>
            <th>등록일</th>
            <th>현재고</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item) => (
              <ProductBasicUnit
                key={item.id}
                item={item}
                isUnitMode={isUnitMode}
                memberById={memberById}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

function Tx({ tx, memberById }) {
  return (
    <tr>
      <td>{tx.id}</td>
      <td>{TX_TYPE_NAME[tx.tx_type] || "Unknown"}</td>
      <td>{new Date(tx.tx_time).toLocaleString()}</td>
      <td>{tx.count}</td>
      <td>{tx.vendor}</td>
      <td>{tx.memo}</td>
      <td>
        <MemberName id={tx.created_user_id} memberById={memberById} />
      </td>
    </tr>
  );
}

function TxList({ txs, memberById }) {
  return (
    <div>
      <table className="pure-table pure-table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>TYPE</th>
            <th>시간</th>
            <th>상품 개수</th>
            <th>거래처</th>
            <th>메모</th>
            <th>유저</th>
          </tr>
        </thead>
        <tbody>
          {txs &&
            txs.map((tx) => <Tx key={tx.id} tx={tx} memberById={memberById} />)}
        </tbody>
      </table>
    </div>
  );
}

function Location({ location, acc }) {
  return (
    <tr>
      <td>{location.id}</td>
      <td>{location.name}</td>
      <td>{location.memo}</td>
      <td>{acc.get(location.id) || 0}</td>
    </tr>
  );
}

function Locations({ locations, acc }) {
  return (
    <table className="pure-table pure-table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>이름</th>
          <th>메모</th>
          <th>재고</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((location) => (
          <Location key={location.id} location={location} acc={acc} />
        ))}
      </tbody>
    </table>
  );
}

function Barcode({ item, acc }) {
  return (
    <tr>
      <td>{item.id}</td>
      <td>
        <a href={item.photo_url} target="_blank">
          <img src={item.photo_url} style={{ width: "50px", height: "50px" }} />
        </a>
      </td>
      <td>
        <a href={"https://barcode.bgp.works/" + item.barcode} target="_blank">
          {item.barcode}
        </a>
        <br />
        {item.name}
      </td>
      <td>{renderAttr(item.attr)}</td>
      <td>{new Date(item.created_time).toLocaleString()}</td>
      <td>{acc.get(item.id) || "0"}</td>
    </tr>
  );
}

function BarcodeList({ items, itemsCount, acc }) {
  return (
    <div>
      <p>상품 개수: {itemsCount}</p>
      <p>
        마지막 입력일:{" "}
        {items && items.length > 0 && formatTime(items[0].created_time)}
      </p>
      <table className="pure-table pure-table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>사진</th>
            <th>상품</th>
            <th>제품 속성</th>
            <th>등록일</th>
            <th>현재고</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item) => (
              <Barcode key={item.id} item={item} acc={acc} />
            ))}
        </tbody>
      </table>
    </div>
  );
}

function LocationName({ id, locationById }) {
  const item = locationById.get(id);
  return item ? item.name : `삭제됨 (${id})`;
}

function Ltx({ ltx, memberById, locationById }) {
  return (
    <tr>
      <td>{ltx.id}</td>
      <td>{LTX_TYPE_NAME[ltx.tx_type] || "Unknown"}</td>
      <td>{new Date(ltx.tx_time).toLocaleString()}</td>
      <td>
        {ltx.from_location_id && (
          <React.Fragment>
            <LocationName
              id={ltx.from_location_id}
              locationById={locationById}
            />
            {" → "}
          </React.Fragment>
        )}
        <LocationName id={ltx.to_location_id} locationById={locationById} />
      </td>
      <td>{ltx.count}</td>
      <td>{ltx.vendor}</td>
      <td>{ltx.memo}</td>
      <td>
        <MemberName id={ltx.created_user_id} memberById={memberById} />
      </td>
    </tr>
  );
}

function LtxList({ ltxs, memberById, locationById }) {
  return (
    <div>
      <table className="pure-table pure-table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>TYPE</th>
            <th>시간</th>
            <th>위치</th>
            <th>상품 개수</th>
            <th>거래처</th>
            <th>메모</th>
            <th>유저</th>
          </tr>
        </thead>
        <tbody>
          {ltxs &&
            ltxs.map((ltx) => (
              <Ltx
                key={ltx.id}
                ltx={ltx}
                memberById={memberById}
                locationById={locationById}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

function quot(a, b) {
  return (a - (a % b)) / b;
}

function calcStripeBillingUnit(xformUsage, quantity) {
  if (xformUsage == null) {
    return quantity;
  }

  const divideBy = xformUsage.divide_by;
  switch (xformUsage.round) {
    case "up":
      return quot(quantity + divideBy - 1, divideBy);

    case "down":
      return quot(quantity, divideBy);

    default:
      console.error("Unexpected round method:", xformUsage);
      return quot(quantity + divideBy - 1, divideBy);
  }
}

function calcStripeItemPrice(item) {
  if (item.plan.billing_scheme != "per_unit") {
    console.error("Unexpected billing_scheme.");
    return -666;
  }
  const price = item.plan.amount;
  const xformUsage = item.plan.transform_usage;
  const billingUnit = calcStripeBillingUnit(xformUsage, item.quantity);
  return billingUnit * price;
}

function RenderCsMemoRow({
  item,
  currentUserEmail,
  onEditCsMemo,
  onDeleteCsMemo,
}) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [memo, setMemo] = React.useState(item.memo);
  const byMe = item.cs_user_email === currentUserEmail;
  if (isEdit) {
    return (
      <tr>
        <td colSpan={3}>
          <textarea
            value={memo}
            onChange={(evt) => setMemo(evt.target.value)}
            rows={3}
            cols={50}
            placeholder="CS 메모 입력후 엔터. 줄바꿈은 Shift + enter"
            onKeyUp={(evt) => {
              if (evt.key === "Enter" && !evt.shiftKey) {
                evt.preventDefault();
                const memo = evt.target.value;
                onEditCsMemo(memo);
                setIsEdit(false);
              }
            }}
          />
        </td>
        <td>
          {byMe && (
            <span>
              <button
                className="pure-button"
                onClick={() => {
                  onEditCsMemo(memo);
                  setIsEdit(false);
                }}
              >
                수정완료
              </button>
              <button
                className="pure-button"
                style={{
                  background: "rgb(202, 60, 60)",
                  color: "white",
                }}
                onClick={() => {
                  setIsEdit(false);
                  setMemo(item.memo);
                }}
              >
                취소
              </button>
            </span>
          )}
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <td>{new Date(item.created_time).toLocaleString()}</td>
      <td>
        <a href={`mailto:${item.cs_user_email}`}>{item.cs_user_name}</a>
      </td>
      <td>
        <pre>{item.memo}</pre>
      </td>
      <td>
        {byMe && (
          <span>
            <button className="pure-button" onClick={() => setIsEdit(true)}>
              수정
            </button>
            <button
              className="pure-button"
              style={{
                background: "rgb(202, 60, 60)",
                color: "white",
              }}
              onClick={() => onDeleteCsMemo(item)}
            >
              삭제
            </button>
          </span>
        )}
      </td>
    </tr>
  );
}

function RenderNewCsMemoInput({ onSubmitCsMemo }) {
  const [memo, setMemo] = React.useState("");
  return (
    <div>
      <textarea
        value={memo}
        onChange={(evt) => setMemo(evt.target.value)}
        rows={3}
        cols={50}
        placeholder="CS 메모 입력후 엔터. 줄바꿈은 Shift + enter"
        onKeyUp={(evt) => {
          if (evt.key === "Enter" && !evt.shiftKey) {
            evt.preventDefault();
            onSubmitCsMemo(memo);
          }
        }}
      />
      <button
        className="pure-button"
        style={{ verticalAlign: "top" }}
        onClick={() => {
          onSubmitCsMemo(memo);
        }}
      >
        작성
      </button>
    </div>
  );
}

const TeamDetail = createReactClass({
  getInitialState: function () {
    return {
      data: null,
      tagsUserInput: "",
      tagsSaveStatus: "",
      barcodeImgDownProgress: null,
      skuImgDownProgress: null,
      memberById: {},
    };
  },

  load: function () {
    const teamId = this.props.match.params.team_id;
    api
      .get(`/api/teams/${teamId}`)
      .then((data) => {
        const tags = data && data.team && data.team.tags;
        this.setState({
          data: data,
          tagsUserInput: Array.isArray(tags) ? data.team.tags.join(", ") : "",
          memberById: toMapById(data.members),
        });
      })
      .catch((err) => alert(err.message));
  },

  makeSubs: function (plan, intervalCnt) {
    const desc = prompt("구독 생성하시겠습니까? 로그를 남겨주세요.", "");
    if (desc != null) {
      const teamId = this.props.match.params.team_id;
      api
        .post(`/api/teams/${teamId}/subs/v2`, {
          plan: plan,
          interval_cnt: intervalCnt,
          desc: desc,
        })
        .then(
          (data) => {
            alert("구독 생성 완료. 잔고를 확인해 주세요.");
            this.load();
          },
          (err) => {
            alert(err.message);
          },
        );
    }
  },

  addDeposit: function (plan, intervalCnt) {
    const desc = prompt("잔고를 충전하시겠습니까? 로그를 남겨주세요.", "");
    if (desc != null) {
      const teamId = this.props.match.params.team_id;
      api
        .post(`/api/teams/${teamId}/add-deposit`, {
          plan: plan,
          interval_cnt: intervalCnt,
          desc: desc,
        })
        .then(
          (data) => {
            alert("완료. 잔고를 확인해 주세요.");
            this.load();
          },
          (err) => {
            alert(err.message);
          },
        );
    }
  },

  changeBillingAnchor: function (subs_id) {
    if (
      confirm(
        "지금으로 결제일을 변경하시겠습니까? 결제일을 변경하면 지금 바로 과금됩니다.",
      )
    ) {
      const teamId = this.props.match.params.team_id;
      api
        .put(`/api/teams/${teamId}/change-billing-anchor-now`, {
          subs_id: subs_id,
        })
        .then(
          (data) => {
            alert("결제일 변경 완료.");
            this.load();
          },
          (err) => {
            alert(err.message);
          },
        );
    }
  },

  sendExtExpiryPush: function () {
    const teamId = this.props.match.params.team_id;
    api.post(`/api/teams/${teamId}/push-ext-expiry`, {}).then(
      (data) => {
        alert("푸쉬 발송 요청 완료");
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  sendExtLowStockAlarmPush: function () {
    const teamId = this.props.match.params.team_id;
    api.post(`/api/teams/${teamId}/push-ext-low-stock-alarm`, {}).then(
      (data) => {
        alert("푸쉬 발송 요청 완료");
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  deleteLabelDesign: function (id) {
    if (confirm("삭제하시겠습니까? 삭제 후 되돌릴수 없습니다.")) {
      const teamId = this.props.match.params.team_id;
      api.del(`/api/teams/${teamId}/label-design/${id}`, {}).then(
        (data) => {
          alert("삭제 완료");
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
    }
  },

  extendFreeDemo: function () {
    var days = prompt("몇일 무료 체험을 연장 하시겠습니까?", "30");
    if (days) {
      const teamId = this.props.match.params.team_id;
      api
        .put(`/api/teams/${teamId}/extend-free-demo`, { days: parseInt(days) })
        .then(
          (data) => {
            alert("연장 완료");
            this.load();
          },
          (err) => {
            alert(err.message);
          },
        );
    }
  },

  resendEmailVerify: function (userId, email) {
    if (
      confirm(`유저 이메일(${email})로 24시간 유효기간 인증코드를 발송합니다.`)
    ) {
      const teamId = this.props.match.params.team_id;
      api.post(`/api/users/${userId}/email-verify`, {}).then(
        (data) => {
          alert("발송 완료:" + data.code);
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
    }
  },

  downloadProductImages: function () {
    const outputName = this.state.data.team.name + ".zip";
    const teamId = this.props.match.params.team_id;
    const listApiUrl = `/api/teams/${teamId}/barcode-imgs`;
    this.setState({
      barcodeImgDownProgress: "loading",
    });
    api
      .get(listApiUrl, null)
      .then((respData) => {
        const items = respData.items;
        if (items.length === 0) {
          alert("사진이 등록된 제품이 없습니다.");
          return null;
        }

        return download.downloadBarcodeImages(
          items,
          outputName,
          (doneCount, allCount) => {
            this.setState({
              barcodeImgDownProgress: doneCount + "/" + allCount,
            });
          },
        );
      })
      .then(
        () => {
          this.setState({
            barcodeImgDownProgress: null,
          });
        },
        (error) => {
          console.error(error);
          alert("failed to load photo list: " + error.message);
          this.setState({
            barcodeImgDownProgress: null,
          });
        },
      );
  },

  downloadSKUImages: function () {
    const outputName = this.state.data.team.name + ".zip";
    const teamId = this.props.match.params.team_id;
    const listApiUrl = `/api/teams/${teamId}/sku-imgs`;
    this.setState({
      skuImgDownProgress: "loading",
    });
    api
      .get(listApiUrl, null)
      .then((respData) => {
        const items = respData.items;
        if (items.length === 0) {
          alert("사진이 등록된 제품이 없습니다.");
          return null;
        }

        return download.downloadBarcodeImages(
          items,
          outputName,
          (doneCount, allCount) => {
            this.setState({
              skuImgDownProgress: doneCount + "/" + allCount,
            });
          },
        );
      })
      .then(
        () => {
          this.setState({
            skuImgDownProgress: null,
          });
        },
        (error) => {
          console.error(error);
          alert("failed to load photo list: " + error.message);
          this.setState({
            skuImgDownProgress: null,
          });
        },
      );
  },

  componentDidMount: function () {
    this.load();
  },

  onChangeTags(evt) {
    this.setState({
      tagsUserInput: evt.target.value,
      tagsSaveStatus: "Need save",
    });
  },

  onSaveTags(evt) {
    if (evt.charCode == 13) {
      const teamId = this.props.match.params.team_id;
      api
        .post(`/api/teams/${teamId}/set-tags`, {
          tags: this.state.tagsUserInput,
        })
        .then(
          (data) => {
            this.setState({
              tagsSaveStatus: "Saved",
            });
          },
          (err) => {
            alert(err.message);
          },
        );
    }
  },

  onClickJoinTeam(evt) {
    if (confirm("정말 정말 이 팀에 합류하시겠습니까?")) {
      const teamId = this.props.match.params.team_id;
      api.post(`/api/teams/${teamId}/join`, {}).then(
        (data) => {
          window.open(this.state.data["web-link"], "_blank");
        },
        (err) => {
          alert(err.message);
        },
      );
    }
  },

  onClickClearTxs(team, _evt) {
    const confirmText = team.name;
    const answer = prompt(
      `정말 초기화하시겠습니까?\n확인을 위해 '${confirmText}' 를 입력해 주세요.\n초기화 후 되돌릴 수 없으며 반드시 백업을 해두세요.`,
    );

    if (answer === null) {
      return;
    }

    if (answer !== confirmText) {
      alert("입력이 일치하지 않습니다.");
      return;
    }

    const teamId = this.props.match.params.team_id;
    const handleSuccess = (_data) => {
      alert("히스토리가 초기화 되었습니다.");
    };
    const handleFail = (err) => {
      alert(err.message);
    };

    if (team.mode === constants.teamModeLocation) {
      api
        .del(`/api/teams/${teamId}/location-txs`, {})
        .then(handleSuccess, handleFail);
      return;
    }

    api.del(`/api/teams/${teamId}/txs`, {}).then(handleSuccess, handleFail);
  },

  onClickSwapBillingInfo(evt) {
    const sourceTeamId = this.props.match.params.team_id;
    const resp = window.prompt("이관 받을 팀 ID를 입력해주세요.", "");
    if (resp == null || resp == "") {
      return;
    }
    const teamId = parseInt(resp);
    if (!isFinite(teamId)) {
      alert("올바르지 않은 팀 ID 입니다.");
      return;
    }
    api
      .get(`/api/teams/${teamId}/simple`)
      .then((data) => {
        const targetTeam = data.team || {};
        const msg = `아래 팀이 맞나요?

팀 ID: ${targetTeam.id}
팀 이름: ${targetTeam.name}
생성일: ${new Date(targetTeam.created_time).toLocaleString()}
마지막 사용: ${new Date(targetTeam.last_access_time).toLocaleString()}

결제 정보가 이관됩니다. (swap)
`;
        if (window.confirm(msg)) {
          api
            .put(
              `/api/teams/${sourceTeamId}/swap-billing?target_team_id=${teamId}`,
              {},
            )
            .then((data) => {
              alert("Done");
              this.load();
            })
            .catch((err) => alert("이관 실패: " + err.message));
        } else {
          alert("아님 말구...");
        }
      })
      .catch((err) => alert(err.message));
  },

  onClickCopyMemberInfo(evt) {
    const sourceTeamId = this.props.match.params.team_id;
    const resp = window.prompt("이관 받을 팀 ID를 입력해주세요.", "");
    if (resp == null || resp == "") {
      return;
    }
    const teamId = parseInt(resp);
    if (!isFinite(teamId)) {
      alert("올바르지 않은 팀 ID 입니다.");
      return;
    }
    api
      .get(`/api/teams/${teamId}/simple`)
      .then((data) => {
        const targetTeam = data.team || {};
        const msg = `아래 팀이 맞나요?

팀 ID: ${targetTeam.id}
팀 이름: ${targetTeam.name}
생성일: ${new Date(targetTeam.created_time).toLocaleString()}
마지막 사용: ${new Date(targetTeam.last_access_time).toLocaleString()}

팀원이 복사됩니다. (사용자 정의 권한은 복사되지 않습니다.)
`;
        if (window.confirm(msg)) {
          api
            .put(
              `/api/teams/${sourceTeamId}/copy-member?target_team_id=${teamId}`,
              {},
            )
            .then((data) => {
              alert("Done");
              this.load();
            })
            .catch((err) => alert("이관 실패: " + err.message));
        } else {
          alert("아님 말구...");
        }
      })
      .catch((err) => alert(err.message));
  },

  onClickCopyVendorInfo(evt) {
    const sourceTeamId = this.props.match.params.team_id;
    const resp = window.prompt("이관 받을 팀 ID를 입력해주세요.", "");
    if (resp == null || resp == "") {
      return;
    }
    const teamId = parseInt(resp);
    if (!isFinite(teamId)) {
      alert("올바르지 않은 팀 ID 입니다.");
      return;
    }
    api
      .get(`/api/teams/${teamId}/simple`)
      .then((data) => {
        const targetTeam = data.team || {};
        const msg = `아래 팀이 맞나요?

팀 ID: ${targetTeam.id}
팀 이름: ${targetTeam.name}
생성일: ${new Date(targetTeam.created_time).toLocaleString()}
마지막 사용: ${new Date(targetTeam.last_access_time).toLocaleString()}

거래처가 복사됩니다.
`;
        if (window.confirm(msg)) {
          api
            .put(
              `/api/teams/${sourceTeamId}/copy-vendor?target_team_id=${teamId}`,
              {},
            )
            .then((data) => {
              alert("Done");
              this.load();
            })
            .catch((err) => alert("이관 실패: " + err.message));
        } else {
          alert("아님 말구...");
        }
      })
      .catch((err) => alert(err.message));
  },

  onClickTeamMigrationUnitToBasic(evt) {
    evt.preventDefault();

    if (!window.confirm("기본모드 팀을 생성하시겠습니까?")) {
      return;
    }

    const teamId = this.props.match.params.team_id;
    const convMethod = this.refs.method.value;
    const keepDanglingBarcode = this.refs.keepDanglingBarcode.checked;

    api
      .post(`/api/teams/${teamId}/migrate-to-basic`, {
        method: convMethod,
        keep_dangling_barcode: keepDanglingBarcode,
      })
      .then(
        (data) => {
          if (data.err) {
            alert(data.err);
            return;
          }
          const newTeamId = data["team-id"];
          if (newTeamId) {
            if (
              window.confirm(
                `팀이 생성되었습니다. (ID: ${newTeamId}). 해당 팀을 여시겠습니까?`,
              )
            ) {
              window.open(window.location.origin + `/#/team/${newTeamId}`);
            }
          } else {
            alert("응답이 올바르지 않습니다. 개발자에게 문의하세요.");
          }
        },
        (err) => {
          alert(err.message);
        },
      );
  },

  onClickTeamMigrationBasicToLoc(evt) {
    evt.preventDefault();

    if (!window.confirm("위치모드 팀을 생성하시겠습니까?")) {
      return;
    }

    const teamId = this.props.match.params.team_id;

    api.post(`/api/teams/${teamId}/migrate-to-loc`, {}).then(
      (data) => {
        if (data.err) {
          alert(data.err);
          return;
        }
        const newTeamId = data["team-id"];
        if (newTeamId) {
          if (
            window.confirm(
              `팀이 생성되었습니다. (ID: ${newTeamId}). 해당 팀을 여시겠습니까?`,
            )
          ) {
            window.open(window.location.origin + `/#/team/${newTeamId}`);
          }
        } else {
          alert("응답이 올바르지 않습니다. 개발자에게 문의하세요.");
        }
      },
      (err) => {
        alert(err.message);
      },
    );
  },

  onClickCopyTeam(evt) {
    evt.preventDefault();

    if (!window.confirm("팀을 복사하시겠습니까?")) {
      return;
    }

    const teamId = this.props.match.params.team_id;

    api
      .post(`/api/teams/${teamId}/copy-team`, {})
      .then(
        (data) => {
          if (data.err) {
            alert(data.err);
            return;
          }
          const newTeamId = data["team-id"];
          if (newTeamId) {
            if (
              window.confirm(
                `팀이 복사되었습니다. (ID: ${newTeamId}). 해당 팀을 여시겠습니까?`,
              )
            ) {
              window.open(window.location.origin + `/#/team/${newTeamId}`);
            }
          } else {
            alert("응답이 올바르지 않습니다. 개발자에게 문의하세요.");
          }
        },
        (err) => {
          alert(err.message);
        },
      );
  },

  onSubmitCsMemo(memo) {
    memo = memo.trim();
    if (window.confirm(`아래 내용으로 등록합니까?\r\r${memo}`)) {
      // submit
      const teamId = this.props.match.params.team_id;
      api.post(`/api/teams/${teamId}/cs-memo`, { memo: memo }).then(
        (data) => {
          if (data.err) {
            alert(data.err);
            return;
          }
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
      return;
    }
  },

  onDeleteCsMemo(memoRow) {
    if (window.confirm(`정말 삭제하시겠습니까??\r\r${memoRow.memo}`)) {
      // submit
      const teamId = this.props.match.params.team_id;
      api.del(`/api/teams/${teamId}/cs-memo/${memoRow.id}`, null).then(
        (data) => {
          if (data.err) {
            alert(data.err);
            return;
          }
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
      return;
    }
  },

  onEditCsMemo(id, newMemo) {
    newMemo = newMemo.trim();
    if (window.confirm(`아래 내용으로 수정하시겠습니까??\r\r${newMemo}`)) {
      // submit
      const teamId = this.props.match.params.team_id;
      api.put(`/api/teams/${teamId}/cs-memo/${id}`, { memo: newMemo }).then(
        (data) => {
          if (data.err) {
            alert(data.err);
            return;
          }
          this.load();
        },
        (err) => {
          alert(err.message);
        },
      );
      return;
    }
  },

  isUnitMode: function () {
    const teamMode = this.state.data && this.state.data.team.mode;
    return teamMode === constants.teamModeUnit;
  },

  renderTags() {
    return (
      <div>
        <label>
          고객군 분류 태그:
          <input
            type="text"
            value={this.state.tagsUserInput}
            onChange={this.onChangeTags}
            onKeyPress={this.onSaveTags}
            style={{ width: "400px" }}
            placeholder="여러 태그는 ','로 구분. 엔터키 누르면 저장."
          />
          <span style={{ marginLeft: 10 }}>{this.state.tagsSaveStatus}</span>
        </label>
      </div>
    );
  },

  isLocationMode: function () {
    const teamMode = this.state.data && this.state.data.team.mode;
    return teamMode === constants.teamModeLocation;
  },

  renderTzOffsetMin(min) {
    const sign = min < 0 ? "-" : "+";
    const absMin = Math.abs(min);
    const h = Math.floor(absMin / 60);
    const m = absMin % 60;
    const leadingZero = (num) => ("0" + num).slice(-2);
    return `UTC${sign}${leadingZero(h)}:${leadingZero(m)}`;
  },

  renderCopyTeam() {
    return <li>
      <button
        className="pure-button pure-button-primary"
        style={{ marginTop: 15 }}
        onClick={this.onClickCopyTeam}
      >
        팀 복사
      </button>
      <span
        className="pure-form-message"
        style={{ margin: "5px 0px 5px" }}
      >
        이 팀의 복제 팀이 생성됩니다. 입출고 데이터는 복사되지 않습니다.
      </span>
    </li>
  },

  renderTeamMigration: function (team) {
    if (team.mode === constants.teamModeUnit) {
      return (
        <li>
          <div
            style={{
              border: "1px solid black",
              margin: 10,
              padding: 10,
              backgroundColor: "#feefef",
            }}
          >
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>기본모드로 전환</legend>
                <span
                  className="pure-form-message"
                  style={{ margin: "5px 0px 20px" }}
                >
                  이 팀은 그대로 있고, 새로운 팀이 생성됩니다.
                </span>
                <label htmlFor="method">제품 바코드 설정:</label>
                <select id="method" ref="method" defaultValue="keep-barcode">
                  <option value="keep-barcode">제품 바코드</option>
                  <option value="keep-sku">유닛 SKU</option>
                </select>
                <label htmlFor="keepDanglingBarcode" className="pure-checkbox">
                  <input
                    id="keepDanglingBarcode"
                    ref="keepDanglingBarcode"
                    type="checkbox"
                  />
                  유닛이 없는 제품도 복사
                </label>
                <button
                  className="pure-button pure-button-primary"
                  style={{ marginTop: 15 }}
                  onClick={this.onClickTeamMigrationUnitToBasic}
                >
                  기본모드로 변환
                </button>
              </fieldset>
            </form>
          </div>
        </li>
      );
    } else if (team.mode === constants.teamModeBasic) {
      return (
        <li>
          <button
            className="pure-button pure-button-primary"
            style={{ marginTop: 15 }}
            onClick={this.onClickTeamMigrationBasicToLoc}
          >
            위치모드로 변환
          </button>
          <span
            className="pure-form-message"
            style={{ margin: "5px 0px 20px" }}
          >
            이 팀은 그대로 있고, 새로운 팀이 생성됩니다. 쇼핑몰 연동은 복사되지
            않습니다.
          </span>
        </li>
      );
    } else {
      return null;
    }
  },

  renderInitHistory: function (team) {
    return (
      <li>
        <button
          className="pure-button"
          onClick={this.onClickClearTxs.bind(this, team)}
          style={{
            background: "rgb(202, 60, 60)",
            color: "white",
          }}
        >
          히스토리 초기화
        </button>
        <span className="pure-form-message" style={{ margin: "5px 0px" }}>
          해당 팀의 모든 재고조사, 히스토리, 재고데이터가 삭제되며 되돌릴 수
          없는 작업입니다.
        </span>
      </li>
    );
  },

  renderTeam: function (team, webLink, mobileLink) {
    return (
      <ul>
        <li>ID: {team.id}</li>
        <li>이름 : {team.name}</li>
        <li>
          모드 :
          <TeamMode mode={team.mode} />
        </li>
        <li>
          생성일 : {new Date(team.created_time).toLocaleString()} (마지막 사용{" "}
          {new Date(team.last_access_time).toLocaleString()})
        </li>
        <li>
          TZ :
          <TzInfo
            name={team.tz_name}
            offsetMin={team.tz_offset_min}
            twoLine={false}
          />
        </li>
        <li>
          <a target="_blank" href={webLink}>
            {" "}
            <button className="pure-button">훔쳐 보기</button>
          </a>
          <button
            className="pure-button"
            onClick={this.onClickJoinTeam}
            style={{
              marginLeft: 20,
              background: "rgb(202, 60, 60)",
              color: "white",
            }}
          >
            합류 하기
          </button>
        </li>
        <li>
          <a href={mobileLink}>
            <QRCode value={mobileLink} />
          </a>
        </li>
        <li>
          <button className="pure-button" onClick={this.sendExtExpiryPush}>
            유통기한푸쉬보내기
          </button>
          <button
            className="pure-button"
            onClick={this.sendExtLowStockAlarmPush}
          >
            재고부족푸쉬보내기
          </button>
          <button className="pure-button" onClick={this.downloadProductImages}>
            제품이미지다운로드
            {this.state.barcodeImgDownProgress &&
              "..." + this.state.barcodeImgDownProgress}
          </button>
          {this.isUnitMode() && (
            <button className="pure-button" onClick={this.downloadSKUImages}>
              SKU 이미지다운로드
              {this.state.skuImgDownProgress &&
                "..." + this.state.skuImgDownProgress}
            </button>
          )}
        </li>
        <li>{this.renderTags()}</li>
        {this.renderCopyTeam(team)}
        {this.renderTeamMigration(team)}
        {this.renderInitHistory(team)}
      </ul>
    );
  },

  renderBilling: function (team, cus) {
    var subsRow = (sub, i) => {
      const canceled = sub.canceled_at != null;
      return (
        <tr key={sub.id}>
          <td>{new Date(sub.start_date * 1000).toLocaleString()}</td>
          <td>
            {new Date(sub.current_period_end * 1000).toLocaleString()}
            <br />
            <span
              className="text-btn"
              onClick={this.changeBillingAnchor.bind(this, sub.id)}
            >
              현재로 결제일 변경
            </span>
          </td>
          <td>
            {sub.items.data.map(function (item) {
              return (
                <div key={item.id}>
                  {item.plan.nickname} ({item.quantity}EA)
                </div>
              );
            })}
          </td>
          <td>
            {sub.items.data.map(function (item) {
              return (
                <div key={item.id}>
                  ${calcStripeItemPrice(item) / 100} /{" "}
                  {item.plan.interval_count} {item.plan.interval}
                </div>
              );
            })}
          </td>
          <td>
            {canceled ? (
              <span
                style={{ textDecoration: "line-through" }}
              >{`${sub.status} (canceled)`}</span>
            ) : (
              sub.status
            )}
          </td>
          <td>
            <a
              target="_blank"
              href={"https://dashboard.stripe.com/subscriptions/" + sub.id}
            >
              {" "}
              <button className="pure-button">Stripe에서 보기</button>
            </a>
          </td>
        </tr>
      );
    };

    var subsTable = (subs) => {
      return (
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>시작일</th>
              <th>다음 결제일</th>
              <th>Plan</th>
              <th>가격</th>
              <th>상태</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{subs.map(subsRow)}</tbody>
        </table>
      );
    };

    var noSubs = () => {
      return (
        <div>
          <button
            className="pure-button"
            onClick={this.makeSubs.bind(this, "biz-yearly", 1)}
          >
            BIZ-YEARLY 계좌이체
          </button>
        </div>
      );
    };

    var addDeposits = () => {
      return (
        <div>
          <button
            className="pure-button"
            onClick={this.addDeposit.bind(this, "biz-yearly", 1)}
          >
            BIZ-YEARLY 계좌이체 (잔고 추가)
          </button>
        </div>
      );
    };

    if (cus == null) {
      return <div>NO customer</div>;
    }

    return (
      <div>
        <ul>
          <li>플랜 : {team.plan}</li>
          <li>
            체험 종료 :{" "}
            {team.trial_end == null
              ? "체험 기간 없음"
              : new Date(team.trial_end).toLocaleString()}
            <button className="pure-button" onClick={this.extendFreeDemo}>
              체험 연장
            </button>
          </li>
          <li>Stripe ID : {team.stripe_id}</li>
          <li>
            <a
              target="_blank"
              href={"https://dashboard.stripe.com/customers/" + team.stripe_id}
            >
              {" "}
              <button className="pure-button">Stripe에서 보기</button>
            </a>
          </li>
          <li>
            <ReactJson src={cus} collapsed={true} />
          </li>
          <li>잔고: ${cus && -cus.balance / 100}</li>
          <li>
            <button
              className="pure-button"
              style={{
                background: "rgb(202, 60, 60)",
                color: "white",
              }}
              onClick={this.onClickSwapBillingInfo}
            >
              결제 정보 이관
            </button>
            <button
              className="pure-button"
              style={{
                background: "rgb(202, 60, 60)",
                color: "white",
                marginLeft: 5,
              }}
              onClick={this.onClickCopyMemberInfo}
            >
              멤버 정보 이관
            </button>
            <button
              className="pure-button"
              style={{
                background: "rgb(202, 60, 60)",
                color: "white",
                marginLeft: 5,
              }}
              onClick={this.onClickCopyVendorInfo}
            >
              거래처 정보 이관
            </button>
          </li>
        </ul>

        <div>
          {cus.subscriptions.data.length > 0
            ? subsTable(cus.subscriptions.data)
            : "No subscription yet."}
        </div>
        <div>
          {cus.subscriptions.data.length > 0 ? addDeposits() : noSubs()}
        </div>
      </div>
    );
  },

  renderMember: function (members) {
    const emailVerify = (member) => {
      if (member.email_id) {
        return (
          <button
            onClick={this.resendEmailVerify.bind(
              this,
              member.id,
              member.email_id,
            )}
          >
            인증 코드 발송
          </button>
        );
      }
    };

    const renderRow = (member, i) => {
      return (
        <tr key={i}>
          <td>{member.id}</td>
          <td style={{ whiteSpace: "nowrap" }}>
            {ROLE_TYPES[member.role_type]}
          </td>
          <td>
            <a href={member.profile_img} target="_blank">
              <img
                src={member.profile_img}
                style={{ width: "50px", height: "50px" }}
              />
            </a>
          </td>
          <td>
            <Link to={"/user?q=" + encodeURIComponent(member.name)}>
              {member.name}
            </Link>
          </td>
          <td>
            <LoginMethod user={member} />
            {emailVerify(member)}
          </td>
          <td>
            <AdsList ads={member.ads} />
          </td>
          <td>
            <Country code={member.country_code} name={member.country_name} />
          </td>
          <td>
            {new Date(member.last_login).toLocaleString()}
            <SessionList items={member.clients} />
          </td>
          <td>{new Date(member.created_time).toLocaleString()}</td>
        </tr>
      );
    };

    return (
      <div>
        <p>총 {members.length}명</p>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>권한</th>
              <th>사진</th>
              <th>이름</th>
              <th>로그인</th>
              <th>광고</th>
              <th>국가</th>
              <th>최근 접속일 : 최근 접속 기기</th>
              <th>가입일</th>
            </tr>
          </thead>
          <tbody>{members.map(renderRow)}</tbody>
        </table>
      </div>
    );
  },

  renderAttr: function (attrs) {
    function renderRow(item, i) {
      return (
        <tr key={i}>
          <td>{item.id}</td>
          <td>{item.attr_name}</td>
          <td>{item.attr_type}</td>
        </tr>
      );
    }

    return (
      <div>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>이름</th>
              <th>TYPE</th>
            </tr>
          </thead>
          <tbody>{attrs.map(renderRow)}</tbody>
        </table>
      </div>
    );
  },

  renderLabel: function (items) {
    function renderRow(item, i) {
      var template = encodeURIComponent(JSON.stringify(item.label_json));
      var data = encodeURIComponent(JSON.stringify({}));
      const mm2pt = 2.83465;
      var w = item.paper_json.width * mm2pt;
      var h = item.paper_json.height * mm2pt;

      return (
        <tr key={i}>
          <td>{item.id}</td>
          <td>{item.label_type}</td>
          <td>
            {item.paper_json.width + "mm X " + item.paper_json.height + "mm"}
          </td>
          <td>
            <img
              src={`https://label.bgp.works/preview?w=${w}&h=${h}&scale=2&template=${template}&data=${data}`}
            ></img>
          </td>
          <td>{new Date(item.created_time).toLocaleString()}</td>
          <td>
            <button
              className="pure-button"
              onClick={this.deleteLabelDesign.bind(this, item.id)}
            >
              삭제
            </button>
          </td>
        </tr>
      );
    }

    return (
      <div>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>TYPE</th>
              <th>Size</th>
              <th>Preview</th>
              <th>생성일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{items.map(renderRow.bind(this))}</tbody>
        </table>
      </div>
    );
  },

  renderBasic: function () {
    return (
      <React.Fragment>
        <h1>제품 속성</h1>
        {this.renderAttr(this.state.data["barcode-attrs"])}

        <h1>제품 정보</h1>
        <ProductsListBasicUnit
          items={this.state.data.products}
          itemsCount={this.state.data["products-count"]}
          isUnitMode={false}
          memberById={this.state.memberById}
        />

        <h1>TX 정보</h1>
        <TxList txs={this.state.data.txs} memberById={this.state.memberById} />
      </React.Fragment>
    );
  },

  renderUnit: function () {
    return (
      <React.Fragment>
        <h1>제품 속성</h1>
        {this.renderAttr(this.state.data["barcode-attrs"])}

        <h1>유닛 속성</h1>
        {this.renderAttr(this.state.data["product-attrs"])}

        <h1>제품 정보</h1>
        <ProductsListBasicUnit
          items={this.state.data.products}
          itemsCount={this.state.data["products-count"]}
          isUnitMode={true}
          memberById={this.state.memberById}
        />

        <h1>TX 정보</h1>
        <TxList txs={this.state.data.txs} memberById={this.state.memberById} />
      </React.Fragment>
    );
  },

  renderLocation: function () {
    const qtyByLocationId = this.state.data.quantities.reduce(function (
      aux,
      elem,
    ) {
      const qty = aux.get(elem.location_id) || 0;
      aux.set(elem.location_id, qty + elem.quantity);
      return aux;
    }, new Map());

    const qtyByBarcodeId = this.state.data.quantities.reduce(function (
      aux,
      elem,
    ) {
      const qty = aux.get(elem.barcode_id) || 0;
      aux.set(elem.barcode_id, qty + elem.quantity);
      return aux;
    }, new Map());

    const locationById = toMapById(this.state.data.locations);

    return (
      <React.Fragment>
        <h1>제품 속성</h1>
        {this.renderAttr(this.state.data["barcode-attrs"])}

        <h1>위치 정보</h1>
        <Locations
          locations={this.state.data.locations}
          acc={qtyByLocationId}
        />

        <h1>제품 정보</h1>
        <BarcodeList
          items={this.state.data.barcodes}
          itemsCount={this.state.data["barcodes-count"]}
          acc={qtyByBarcodeId}
        />

        <h1>TX 정보</h1>
        <LtxList
          ltxs={this.state.data.ltxs}
          memberById={this.state.memberById}
          locationById={locationById}
        />
      </React.Fragment>
    );
  },

  renderByMode() {
    const teamMode = this.state.data && this.state.data.team.mode;

    switch (teamMode) {
      case constants.teamModeBasic:
        return this.renderBasic();

      case constants.teamModeUnit:
        return this.renderUnit();

      case constants.teamModeLocation:
        return this.renderLocation();

      default:
        return null;
    }
  },

  renderCsMemo(items) {
    const currentUser = firebase.auth().currentUser;
    const currentUserEmail = currentUser && currentUser.email;
    return (
      <React.Fragment>
        <RenderNewCsMemoInput
          onSubmitCsMemo={(memo) => this.onSubmitCsMemo(memo)}
        />
        {items.length > 0 && (
          <table className="pure-table pure-table-bordered">
            <thead>
              <tr>
                <th>작성일</th>
                <th>작성자</th>
                <th>Memo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => {
                return (
                  <RenderCsMemoRow
                    key={item.id}
                    item={item}
                    currentUserEmail={currentUserEmail}
                    onEditCsMemo={(memo) => {
                      this.onEditCsMemo(item.id, memo);
                    }}
                    onDeleteCsMemo={(item) => {
                      this.onDeleteCsMemo(item);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  },

  render: function () {
    if (!this.state.data) {
      return <div>로드중..</div>;
    }
    return (
      <div>
        <h1>팀 정보</h1>
        {this.renderTeam(
          this.state.data.team,
          this.state.data["web-link"],
          this.state.data["mobile-link"],
        )}

        <h1>CS 메모</h1>
        {this.renderCsMemo(this.state.data["cs-memos"])}

        <h1>결제 정보</h1>
        {this.renderBilling(this.state.data.team, this.state.data.stripe)}

        <h1>멤버 정보</h1>
        {this.renderMember(this.state.data.members)}

        <h1>라벨 디자인</h1>
        {this.renderLabel(this.state.data["label-designs"])}

        {this.renderByMode()}
      </div>
    );
  },
});

module.exports = TeamDetail;
